
<template>
  <div class="mt-4">

    <vx-tour
      name="questionnaireResumeIntroSteps"
      :auto="false"
      :delegate="tourStart"
    />

    <vx-tour
      name="questionnaireResumeExplanationSteps"
      :auto="false"
      ref="questionnaireResumeExplanationSteps"
    />

    <div class="flex items-start sm:items-center justify-between mb-4">
      <div class="w-full flex-grow flex gap-4 t">
        <!-- <div class="flex items-center gap-2 justify-left" @click="getResume(true)" style="cursor: pointer;">
          <feather-icon
            icon="RefreshCwIcon"
            svgClasses="h-4 w-4"
            class="ml-1 tour-resume-refresh"
            v-tooltip.top="{
              content: 'Atualizar',
              delay: { show: 1000, hide: 100 },
            }"
          />
          <b>Atualizar</b>
        </div> -->
        <div
          v-tooltip.top="{
              content: 'Atualizar',
              delay: { show: 1000, hide: 100 },
          }"
        >
          <vs-button text-color="var(--content-color)" type="flat" @click="getResume(true)" class="px-2 py-2">
            <font-awesome-icon icon="sync-alt" />
            <div class="inline pl-1 font-semibold">{{ $t('atualizar') }}</div>
          </vs-button>
        </div>
        <div
          v-tooltip.top="{
            content: 'Realiza o calculo da media, mediana e estatísticas de duração novamente.',
            delay: { show: 1000, hide: 100 },
          }"
        >
          <vs-button text-color="var(--content-color)" type="flat" @click="calcScores" class="px-2 py-2">
            <font-awesome-icon icon="cogs" />
            <div class="inline pl-1 font-semibold">{{ $t('recalcular-dados') }}</div>
          </vs-button>
        </div>
      </div>

      <div class="flex flex-col sm:flex-row gap-2">
        <b>{{ $t('agrupado-por') }}</b>

        <div class="flex flex-row gap-2">
          <label>{{ $t('individual') }}</label>
          <vs-switch v-model="isGeneralResume" />
          <label>{{ $t('general') }}</label>
        </div>
      </div>

    </div>

    <div v-if="isGeneralResume">
      <vs-row v-if="generalResumeList.length === 0" class="flex text-center mt-4">
        <vs-col class="w-full">
          {{ $t('ainda-nao-ha-resumo-geral-disponivel-sel') }}
        </vs-col>
      </vs-row>
      <vx-card v-else :title="Title" v-for="resume of generalResumeList" :key="resume" class="mb-4">
        <div v-html-safe="resume.answerdQuestion.contentQuestion.data.title" class="border-bottom editor-content ck-content"></div>

        <div class="mt-6 grid grid-cols-1 sm:grid-cols-4">
          <div class="flex justify-items-center w-full mb-2 sm:mb-0">
            <div class="grid grid-cols-1 w-full justify-items-center">
              <div>
                <h5>{{ $t('total-respostas-enviadas') }}</h5>
              </div>
              <div>
                  {{resume.total}}
              </div>
            </div>
          </div>

          <div class="flex justify-items-center w-full mb-2 sm:mb-0">
            <div class="grid grid-cols-1 w-full justify-items-center">
              <div>
                <h5>{{ $t('corretas') }}</h5>
              </div>
              <div>
                {{resume.correction_total}}
              </div>
            </div>
          </div>

          <div class="flex justify-items-center w-full mb-2 sm:mb-0">
            <div class="grid grid-cols-1 w-full justify-items-center">
              <div>
                <h5>{{ $t('incorretas') }}</h5>
              </div>
              <div>
                {{resume.incorrection_total}}
              </div>
            </div>
          </div>

          <div class="flex justify-items-center w-full mb-2 sm:mb-0">
            <div class="grid grid-cols-1 w-full justify-items-center">
              <div>
                <h5>{{ $t('aguardando-correcao-0') }}</h5>
              </div>
              <div>
                {{resume.pending_total}}
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 grid grid-cols-1 sm:grid-cols-2">
          <div>
            <e-charts autoresize :options="questionPieResumeData(resume)" ref="pie" auto-resize />
          </div>
          <div 
            v-if="resume.answerdQuestion.contentQuestion.type === 'multiple_choice' || resume.answerdQuestion.contentQuestion.type === 'check_box'"
            class="mt-6 sm:mb-0"
          >
            <e-charts autoresize :options="optionsPieResumeData(resume)" ref="pie" auto-resize />
          </div>
        </div>
      </vx-card>
    </div>

    <div>
      <Grid
        v-show="!isGeneralResume"
        @grid-UPDATED="updateIndividualResumeData"
        direction="asc"
        ref="individualResumeGrid"
        :noDataText="$t('nao-ha-resumos-individuais-disponiveis')"
        :service="questionnaireAnswerService"
        route_name="individual_resume"
        :route_grid_path="`/${id}/resume/individual`"
        :hide_actions="true"
        :fetchOnMounted="false"
      >
        <template v-slot:filterZone>
          <div v-if="showScoreResume" class="flex items-center justify-between sm:flex-row sm:justify-start gap-2 py-2 md:py-0 sm:my-0 h-full">
            <label class="flex-grow sm:flex-grow-0">
              {{ $t('total-de-respostas-unfinished-concluidas', [unfinished ? ' Concluídas' : '']) }} <span class="font-bold">{{answersDone}}</span>
            </label> 
            <label class="flex-grow sm:flex-grow-0 sm:pl-2 border-grey sm:border-solid border-l border-t-0 border-r-0 border-b-0" v-if="unfinished">
              {{ $t('nao-finalizados') }} <span class="font-bold">{{answersProgress}}</span>
            </label> 
            <label class="flex-grow sm:flex-grow-0 sm:pl-2 border-grey sm:border-solid border-l border-t-0 border-r-0 border-b-0" v-if="hasAverage">
              {{ $t('media-das-notas') }} <span class="font-bold">{{formatFloat(average)}}</span> 
            </label>
            <label class="flex-grow sm:flex-grow-0 sm:pl-2 border-grey sm:border-solid border-l border-t-0 border-r-0 border-b-0" v-if="hasMedian">
              Mediana: <span class="font-bold">{{formatFloat(median)}}</span> 
            </label>
          </div>
        </template>
        <template v-slot:gridThead>
          <vs-th
            key="name"
            sort-key="user.name"
            :width="$utils.reactive.ratios('35%', '20%')"
          >
            {{ $t('participant') }}
          </vs-th>

          <vs-th
            key="email"
            sort-key="user.email"
            :width="$utils.reactive.ratios('35%', '25%')"
          >
            {{ $t('email') }}
          </vs-th>

          <vs-th
          key="inProgress"
          sort-key="finished_at"
          :width="$utils.reactive.ratios('15%', '25%')"
          >
            {{ $t('progresso') }}
          </vs-th>

          <vs-th
          key="score"
          sort-key="score"
          :width="$utils.reactive.ratios('15%', '30%')"
          >
            <label>{{ $t('nota') }}</label> <label v-if="totalScoreHeader">{{ $t('formatfloat-totalscoreheader', [formatFloat(totalScoreHeader)]) }}</label>
          </vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :key="props.data[i]" v-for="(tr, i) in props.data">
            <vs-td key="users.name">
              {{tr.name}}
            </vs-td>
            <vs-td key="users.email">
              <div class="px-2 w-32 sm:w-64 md:w-80 lg:w-full truncate">
                {{ tr.email }}
              </div>
            </vs-td>
            <vs-td
              key="inProgress"
              :class="tr.finished_at ? 'text-success' : 'text-warning'"
            >
              {{tr.finished_at ? 'Concluído' : 'Não finalizado'}}
            </vs-td>
            <vs-td key="score">
              <div class="pr-4 flex justify-between gap-1">
                <span :class="scoreGravity(tr.score)">{{scoreFormat(tr)}}</span>
                <span v-if="getRowMaxScore(tr)"> {{ $t('parsefloat-getrowmaxscore-tr', [parseFloat(getRowMaxScore(tr))]) }} </span>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </Grid>

    </div>
  </div>
</template>

<script>

import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'

import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/chart/pie'
const VxTour = () => import('@/components/VxTour.vue')

export default {
  components: {
    ECharts,
    VxTour
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    content_data: {
      type: Object,
      default: null
    },
    averageScore: {
      type: Number,
      default: null,
    }
  },
  data: () => ({
    service: null,
    questionnaireAnswerService: null,
    generalResumeList: [],
    answersTotal: 0,
    answersProgress: 0,
    scoreResume: {median: 0, average: 0, maxTotal: 0},
    showScoreResume: false,
    isGeneralResume: false,
    tourStart: false
  }),
  computed: {
    noResume() {
      return (this.generalResumeList.length === 0 && this.answersTotal === 0)
    },
    answersDone() {
      return this.answersTotal - this.answersProgress
    },
    unfinished() {
      return false//this.answersProgress !== 0
    },
    hasMedian() {
      return this.scoreResume.median !== undefined || this.scoreResume.median !== null
    },
    hasAverage() {
      return this.scoreResume.average !== undefined || this.scoreResume.average !== null
    },
    median() {
      return this.scoreResume.median
    },
    average() {
      return this.scoreResume.average
    },
    totalScoreHeader() {
      return this._.get(this.scoreResume, 'maxTotal', null)
    }
  },
  watch: {
    isGeneralResume(val) {
      this.getResume(true)
    }
  },
  methods: {
    getRowMaxScore(row) {
      return this._.get(row, 'maxScore', null)
    },
    scoreGravity(score) {
      if (!this.averageScore) { return '' }
      const diff = this.averageScore - (+score)
      if (diff <= 0) {
        return 'text-success'
      } else if (diff > 0) {
        return (diff < (this.averageScore / 2)) ? 'text-warning' : 'text-danger'
      }
    },
    calcScores() {
      this.$vs.loading()
      this.service.calcAllQuestionScore(this.id).then(
        (response) => {
          this.notifySuccess(this.$vs, this.$t('sucesso-media-mediana-e-desempenho-nas-q'))
          this.$vs.loading.close()
          this.getResume(false)
        },
        (error) => {
          this.$vs.loading.close()
          this.notifyError(this.$vs, this.$t('erro-ao-tentar-recalcular'))
        }
      )
    },
    scoreFormat(data) {
      const score = data.score ? parseFloat(data.score).toFixed(2).toString() : '0'
      const maxScoreTotal = data.maxScoreTotal ? parseFloat(data.maxScoreTotal).toFixed(2).toString() : ''
      return score.padStart(maxScoreTotal.length, '0')
    },
    updateScore(response) {
      this.scoreResume = response.score
    },
    getResume(showLoading) {
      if (!this.isEmpty(this.id)) {
        if (showLoading) {
          this.$vs.loading()
        }

        if (!this.isGeneralResume) {
          if (this.$refs.individualResumeGrid) {
            this.fetchIndividualResumeGridData(this.$refs.individualResumeGrid)
            this.service.resume(this.id).then(
              response => {
                if (showLoading) {
                  this.$vs.loading.close()
                }
                this.scoreResume = {
                  median: parseFloat((response && response.median) || '0'),
                  average: parseFloat((response && response.average) || '0'),
                  maxTotal: parseFloat((response && response.maxTotalScore) || '0')
                }
                this.showScoreResume = true
              },
              error => {
                if (showLoading) {
                  this.$vs.loading.close()
                }
                console.error(error)
              }
            )
          } else if (showLoading) {
            console.error('Missing individualResume grid reference, try using v-show instead of v-if.')
            this.$vs.loading.close()
          }
        } else {
          this.service.generalResume(this.id).then(
            response => {
              this.generalResumeList = response
              if (showLoading) {
                this.$vs.loading.close()
              }
              if (!this.noResume && !this.tourStarted('questionnaireResumeExplanationSteps')) {
                this.startTour('questionnaireResumeExplanationSteps')
              }
            },
            error => {
              if (showLoading) {
                this.$vs.loading.close()
              }
            }
          )
        }
      }
    },
    fetchIndividualResumeGridData(gridInstance) {
      gridInstance.fetchGridData()
    },
    updateIndividualResumeData(response) {
      this.answersTotal = response.model.total
      this.inProgress++
    },
    questionPieResumeData(resume) {

      return {
        title: {
          text: this.$t('desempenho-geral'),
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 0,
          left: 'center',
          data: [this.$t('total-respostas-enviadas-0'), this.$t('corretas'), this.$t('incorretas'), this.$t('aguardando-correcao-1')]
        },
        series: [
          {
            name: this.$t('desempenho-questao'),
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            color: ['#28C76F', '#EA5455', '#FF9F43'],
            data: [
              {value: resume.correction_total, name: this.$t('corretas')},
              {value: resume.incorrection_total, name: this.$t('incorretas')},
              {value: resume.pending_total, name: this.$t('aguardando-correcao-2')}
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    },
    optionsPieResumeData(resume) {

      const data = []
      const legends = []

      const options = resume.answerdQuestion.contentQuestion.data.options
      options.forEach(option => {
        data.push({
          value: option.total ? option.total : 0,
          name: this.truncate(option.label, 20)
        })
        legends.push(this.truncate(option.label, 20))
      })

      return {
        title: {
          text: this.$t('desempenho-por-opcao'),
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 0,
          left: 'center',
          data: legends
        },
        series: [
          { 
            name: this.$t('desempenho-por-opcao-0'),
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }
    }
  },
  mounted() {
    let dataIndex = -1
    const pie = this.$refs.pie
    if (pie) {
      const dataLen = pie.options.series[0].data.length
      setInterval(() => {
        pie.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex
        })
        dataIndex = (dataIndex + 1) % dataLen
        pie.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex
        })
        pie.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex
        })
      }, 1000)
    }
    this.tourStart = true
    this.getResume(true)
  },
  beforeMount() {
    this.service = ContentQuestionnaireService.build(this.$vs)
    this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
    // setTimeout(() => {
    //   this.getResume(false)
    // }, 2000)
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style lang="scss">
</style>